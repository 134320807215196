body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
  font-size: x-large;
  font-weight: 900;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

#msg {
  z-index: 4;
  width: 100vw;
  height: 100vh;
  background-color: #101820;
  color: #FFD100;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-transform: uppercase;
  opacity: 1;
  transition: opacity 1s;
}

#msg.hidden {
  opacity: 0;
}

#msg > div {
  margin: 1rem;
  transition: opacity 2s;
  opacity: 0;
}

#msg > div > span {
  margin: 1rem;
  transition: opacity 4s;
  opacity: 0;
}

#msg > div.shown, #msg > div > span.shown {
  opacity: 1;
}

#msg > div#amazing {
  font-size: xx-large;
  transition: opacity 15s;
}

#msg > div#finish {
  font-size: xx-large;
  transition: opacity 10s;
}

#from {
  transition: all 4s;
}

#connection-problem {
  opacity: 1;
  display: flex;
  position: fixed;
  z-index: 3;
  width: 100vw;
  height: 100vh;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #FFD100;
  color: #101820;
  font-size: x-large;
  font-weight: 900;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

#warning {
  opacity: 0;
  display: flex;
  position: fixed;
  z-index: 3;
  width: 100vw;
  height: 100vh;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
#warning img {
  animation: blink 1s linear infinite;
}

#warning.shown {
  opacity: 1;
}

@keyframes blink{
  0%{opacity: 0;}
  50%{opacity: .5;}
  100%{opacity: 1;}
}

#connection-problem.hidden {
  opacity: 0;
}

.you {
  border-radius: 50%;
  width: 28px;
  height: 28px;

  background: black;
  border: 2px solid white;
  color: white;

  display: flex;
  justify-content: center;
  align-items: center;
}

.you span {
  padding-top: 3px;
  font-size: 10px;
  font-weight: 900;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

#north {
  color: red;
  z-index: 2;
  position: fixed;
  display: flex;
  width: 100vw;
  height: 100vh;
  justify-content: center;
  align-items: flex-start;
  margin-top: 1em;
}